.ni textarea {
    border: 1px solid black;
    min-width: 800px;
    min-height: 400px;
}

.ni-stuff {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ni-stuff button {
    margin-top: 12px;
}
