.mpow {
    width: 100%;
}

.mpow img {
    width: 400px;
}

.mpow h6 {
    margin: 0px;
}

.mpow p {
    height: 20px;
}

@media