.big {
    width: 100px;
}
.hero {
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.idiot {
    font-size: 12px;
}
.idiot span {
    animation: all 3s infinite;
}
.BIG {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.wordsfromcto {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
}
.BIG h1 {
    margin: 0px;
}
.BIG h5 {
    width: calc(100% - 60px);
}
.CTO {
    width: calc(100% - 60px);
    display: flex;
    align-items: center;
    margin-bottom: 14vh;
}
.CTO p {
    font-size: 10px;
}
.CTO span {
    font-size: 18px;
}
.CTO p {
    margin-left: 14px;
}
p {
    font-size: 14px;
    height: 60px;
}
.Tech {
    width: 300px;
    margin-top: 18px;
}
.Tech h4 {
    margin: 10px 0px;
}
.Tech img {
    width: 250px;
    height: 200px;
}
.Tech button {
    margin-top: 14px;
    margin-left: 0px;
}
.rest {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.business {
}
.technologies {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6vh;
    width: 100%;
}
h1 {
    margin-top:13vh;
    text-align: center;
    margin-bottom: 0px;
}
h5 {
    margin-top: 0px;
}
.window {
    width: 80%;
    margin-top: 15px;
}
body {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .App {
    width: 100%;
  }
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
  }
  html {
    display: flex;
    justify-content: center;
  }
  p {
    margin: 0px;
  }
  h2 {
    margin: 0px;
  }
  .logo {
    width: 200px;
    height: 100%;

  }
  
  .buy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 13vh;
    align-items: center;
  }
  @media (max-width: 1000px) {
    .content {
        text-align: center;
    }
    body {
        width: calc(100% - 60px);
      }
    body {
      font-size: 14px;
    }
    .heading {
        width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 40px 0px;
      }
  }
  @media (max-width: 1300px) {
    .technologies {
        width: 800px;
    }
    .Tech img {
        width: 100%;
        height: 250px;
    }
  }
  @media (max-width: 800px) {
   h1 {
    font-size: 10vw !important;
   }
   h2 {
    font-size: 6vw !important;
   }
  }
  @media (max-width:900px) {
    .technologies {
        width: 400px;
        justify-content: center;
    }
    .Tech {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .Tech button {
        margin-bottom: 60px;
    }
  }

  @media (max-width: 515px) {
    .wordsfromcto h4 {
        text-align: center;
    }
    .CTO {
        flex-direction: column;
    }
    .logo {
        width: 100px;
    }
    .big {
        width: 50px;
        height: 100%;
    }
    h1 {
     font-size: 8.5vw !important;
     margin-top: 5vh;

    }
    .buy {
     margin-top: 5vh;
        
    }
    h2 {
     font-size: 6vw !important;
    }
   }

   body {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .App {
    width: 100%;
  }
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
  }
  p {
    margin: 0px;
  }
  h2 {
    margin: 0px;
  }
  .logo {
    width: 200px;
  }
  
  .buy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }